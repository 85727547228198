<template>
  <div class="UserHome">
      <div class="bottomDiv1" style="width: 97%; height: auto">
        <div style="display: flex; justify-content: space-between;">
          <!-- <div class="index_title" style="border-bottom: none">故障消息</div>
          <div @click="isshow" style="font-size: 14px; color: #333; padding-right: 15px;" >已处理列表</div> -->
        </div>
        <!-- <div v-for="(item,index) in list3" :key="index">
          <div class="card">
          <p>{{ item. malfunction_class}}22</p>
          <p>{{ item. status}}22</p>
          <p>{{ item. device_id}}22</p>
        </div>
        </div> -->
        
        <div class="tableDiv">
          <el-table
            ref="multipleTableRef"
            :data="list3"
            border 
            v-loading="loading"
            height="650px"
            style="
              width: 90%;
              margin: 20px auto;
              overflow-y: auto;
            "
          >
          <el-table-column label="充电站" prop="station_name" />    
            <el-table-column label="设备编号" prop="device_id" />
        
            <el-table-column label="故障类型" prop="status" />
            <el-table-column label="详细情况" prop="details" />
            <el-table-column label="运行状态" prop="create_time" />
            
            <el-table-column label="申报时间" prop="create_time" />
            <el-table-column label="处理人" prop="actoruser" />
            <el-table-column label="处理方式" prop="process_mode" />
            <el-table-column label="所属运营商" prop="username" />
            <el-table-column
              label="操作"
              width="120"
              align="center"
            >
            <template #default="scope">

                <el-button v-show="scope.row.process_mode == null" round color='#42a7a9' plain size="mini" @click="detail(scope.$index, scope.row)">处理</el-button>
                <el-button style="margin:0  !important" v-show="scope.row.process_mode != null" round type="warning" plain size="mini" @click="detail(scope.$index, scope.row)">继续处理</el-button>
              </template>
            </el-table-column>
          </el-table>
        
        </div>
      </div>
    </div>
    <!-- <div class="UserHome">
      <div class="bottomDiv1" style="width: 97%; height: auto">
        <div class="index_title" style="border-bottom: none">系统消息</div>
        <div class="tableDiv">
          <el-table
            ref="multipleTableRef"
            :data="list3"
            border 
            v-loading="loading"
            height="200px"
            style="
              width: 90%;
              margin: 20px auto;
              overflow-y: auto;
            "
          >
            <el-table-column label="设备id" prop="device_id" />
        
            <el-table-column label="故障类型" prop="status" />
            <el-table-column label="详细情况" prop="details" />
            <el-table-column label="申报时间" prop="create_time" />
            <el-table-column label="所属运营商" prop="username" />
            <el-table-column
              label="操作"
              width="90"
              align="center"
            >
              <template #default="scope">
                <div
                  class="BtnSearch1 BtnSearch"
                  @click="detail(scope.$index, scope.row)"
                  >已处理</div
                >
              </template>
            </el-table-column>
          </el-table>
        
        </div>
        <div>

        </div>
      </div>
      
    </div>
    <div class="UserHome">
      <div class="bottomDiv1" style="width: 97%; height: auto">
        <div class="index_title" style="border-bottom: none">业务消息</div>
        <div class="tableDiv">
          <el-table
            ref="multipleTableRef"
            :data="list3"
            border 
            v-loading="loading"
            height="200px"
            style="
              width: 90%;
              margin: 20px auto;
              overflow-y: auto;
            "
          >
            <el-table-column label="设备id" prop="device_id" />
        
            <el-table-column label="故障类型" prop="status" />
            <el-table-column label="详细情况" prop="details" />
            <el-table-column label="申报时间" prop="create_time" />
            <el-table-column label="所属运营商" prop="username" />
            <el-table-column
              label="操作"
              width="90"
              align="center"
            >
              <template #default="scope">
                <div
                  class="BtnSearch1 BtnSearch"
                  @click="detail(scope.$index, scope.row)"
                  >已处理</div
                >
              </template>
            </el-table-column>
          </el-table>
        
        </div>
      </div>
    </div>
     -->
    <!-- 弹窗 -->
    <el-dialog
          v-model="Dialog.editLevy"
          title="故障处理"
          width="90vw"
          :before-close="closeDialog"
          class="dialog"
        >
        <div class="formDiv">
                <el-form
              ref="FormRules"
              :rules="rules"
              :model="FormData"
              label-position="right"
              label-width="80px"
            >
                  <el-form-item label="故障编号:" style="width: 65vw;">
                    <el-input
                      disabled
                      v-model="Dialog.DialogData.id"
                    ></el-input>
                  </el-form-item>
                
                  <el-form-item label="故障情况" style="width: 65vw;">
                    <el-input
                      disabled
                      v-model="Dialog.DialogData.details"
                    ></el-input>
                  </el-form-item>
             
                      <el-form-item label="更换配件:" style="width: 65vw;">
                        <el-radio-group v-model="Dialog.DialogData.change_accessory" @change="radio1">
                        <el-radio :label="0" name="0">需要</el-radio>
                        <el-radio :label="1" name="1">不需要</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  
                      <el-form-item label="配件详情:" style="width: 65vw;">
                    <el-input
                      v-model="Dialog.DialogData.accessories_data"
                      placeholder="请输入配件"
                    ></el-input>
                  </el-form-item>
             
                  <el-form-item label="处理方式:" style="width: 65vw;">
                    <el-input 
                      v-model="Dialog.DialogData.process_mode"
                      placeholder="请输入处理方式"
                    ></el-input>
                  </el-form-item>
              
                  <el-form-item label="处理人:" style="width: 65vw;">
                    <el-input 
                      v-model="Dialog.DialogData.actoruser"
                      placeholder="请输入处理人"
                    ></el-input>
                  </el-form-item>
             
            </el-form>
              </div>
              <template #footer>
                <span class="dialog-footer">
                  <el-button class="Btn" @click="closeDialog">取消</el-button>
                  <el-button class="Btn" type="primary" @click="submitDialog1">保存</el-button>
                  <el-button class="Btn" type="success" @click="submitDialog2">处理完成</el-button>
                </span>
              </template>
      
        </el-dialog>
        <el-dialog
          v-model="Dialog1.editLevy"
          title="已处理问题"
          width="86vw"
          :before-close="closeDialog"
          class="dialog"
        >
        <el-table
            ref="multipleTableRef"
            :data="list3s"
            border 
            v-loading="loading"
            height="400px"
            style="
              width: 90%;
              margin: 20px auto;
              overflow-y: auto;
            "
          >
            <!-- <el-table-column label="id" prop="" /> -->
            <el-table-column label="故障编号" prop="station_name" />    
            <el-table-column label="充电站" prop="station_name" />    
            <el-table-column label="设备编号" prop="device_id" />
            <el-table-column label="运营商" prop="username" />
            <el-table-column label="故障对象" prop="malfunction_class" />
            <el-table-column label="故障类型" prop="status" />
            <el-table-column label="详细情况" prop="details" />
            <el-table-column label="处理方式" prop="process_mode" />
            <el-table-column label="更换配件" prop="change_accessory" />
            <el-table-column label="配件详情" prop="accessories_data" />
          
            
            
            <el-table-column label="故障时间" prop="create_time" />
            <el-table-column label="解决时间" prop="update_time" />
            <el-table-column label="处理人" prop="follow_up_people" />
            <el-table-column label="跟进人" prop="username" />
            
          </el-table>
          <!-- <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" @click="submitDialog">提交</el-button>
            </span>
          </template> -->
      
        </el-dialog>
  </template>
  
  <style scoped >
  /* 标题文字样式 */
  .index_title {
    font-size: 18px;
    color: #333;
    border-left: 5px solid #04a0c3;
    padding-left: 25px;
  }
  .bottomDiv1{
    background-color: #fff;
    padding: 20px;
  }
  .UserHome {
    padding: 2px 30px 2px 2px;
  }
  
  .card{
    padding: 2% ;
    
    }
    .card > p{
        margin-top: 5px;
    
    }
 
  </style>
  
  <script>
  import { onMounted, reactive, toRefs, ref } from "vue-demi";
  import { fault_message,processing_failure1,processing_failure2 } from "@/request/api";
  import { ElMessage } from "element-plus";
  // import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
  export default {
    name: "News",
    // components: {
    //   Vue3SeamlessScroll,
    // },
    setup() {
      const data = reactive({
        change_accessory: '',
        list: [
        
          // }
        ],
        list2: [
          
        ],
        list3:[],
        list3s:[],
        
        //   弹框
        Dialog: {
          editLevy: false,
          DialogData: {},
        },
        Dialog1: {
          editLevy: false,
          DialogData: {},
        },
      });
      
     
      const getList = () => {
      
        const dataa = {
          operator_id :sessionStorage.getItem("userID")
        }
          fault_message(dataa).then((res) => {
            if (res) {
              data.loading = false;
              if (res.code == 200) {
                res.data.forEach((a)=>{
                console.log(a,'aaaaaa');
                if(a.work_type == 0){
                    data.list3.push(a)

                  } else if(a.work_type == 1){
                    data.list3s.push(a)
                  }
                  
            })
              } else {
                ElMessage.error(res.msg);
              }
            } else {
              ElMessage.error("数据获取失败");
            }
          });
          
      };
      const radio1 = (a)=>{
        // data.FormData.type = a
        if(a == '0'){
          
          console.log('000000');
        }else if(a == '1'){
          console.log('111111');
          
        }
      }
      const detail = (index , row) =>{
        console.log(row,'rowww')
        data.Dialog.editLevy = true;
        data.Dialog.DialogData = row;
      }
      const isshow = () => {
        data.Dialog1.editLevy = true;
        
      };
      const closeDialog = () => {
        data.Dialog.editLevy = false;
        data.Dialog1.editLevy = false;
        
      };
      const submitDialog1 = () => {
        console.log('故障处理')
        const dataa = {
          id: data.Dialog.DialogData.id,
          change_accessory: data.Dialog.DialogData.change_accessory == 0?'需要':'不需要',
          accessories_data: data.Dialog.DialogData.accessories_data,
          process_mode: data.Dialog.DialogData.process_mode,
          actoruser: data.Dialog.DialogData.actoruser
        }
          processing_failure1(dataa).then((res) => {
            if (res) {
              if(res.code == 200){
                data.Dialog.editLevy = false;
                ElMessage.success('保存成功')
                
              }
            } else {
              ElMessage.error("保存失败");
            }
          });
      }
      const submitDialog2 = () => {
        console.log('故障处理')
        const dataa = {
          id: data.Dialog.DialogData.id,
          change_accessory: data.Dialog.DialogData.change_accessory,
          accessories_data: data.Dialog.DialogData.accessories_data,
          process_mode: data.Dialog.DialogData.process_mode,
          actoruser: data.Dialog.DialogData.actoruser
        }
          processing_failure2(dataa).then((res) => {
            if (res) {
              ElMessage.success('操作成功')
              data.data.Dialog.editLevy = false;
            } else {
              ElMessage.error("操作失败");
            }
          });
      }
      
      onMounted(() => {
        getList()
      });
      
      return {
        ...toRefs(data),
        detail,
        getList,
        isshow,
        radio1,
        closeDialog,
        submitDialog1,
        submitDialog2
      };
    },
  };
  </script>
  